<template>
	<section class="section has-text-centered pb-0">
		<div class="info">
			<p class="mb-3">
				Produsul <strong>{{ title }}</strong> a fost adăugat. <br />
				Aveți <strong>{{ productsNumber }}</strong> &nbsp;
				<span v-if="productsNumber > 1">produse</span
				><span v-else>produs</span> în listă.
			</p>
			<button class="btn btn--primary" @click="openModalProductsList">
				Vezi coș
			</button>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				require: true,
			},
			productsNumber: {
				type: Number,
				require: true,
			},
		},
		methods: {
			openModalProductsList() {
				this.$store.commit(
					'product/SET_MODAL_PRODUCTS_LIST_STATUS',
					true
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.info {
		box-shadow: inset 0 0 6px rgba($primary, 0.25);
		border: 2px solid rgba($primary, 0.4);
		border-radius: $round-corners;
		display: inline-block;
		padding: 1rem;
	}
</style>

<template>
	<div v-if="slides && slides.length > 0">
		<div class="example-3d">
			<swiper
				ref="swiperTop"
				class="swiper gallery-top"
				:options="swiperOption"
			>
				<swiper-slide
					style="width: 80%; height: 100%;"
					v-for="slide in slides"
					:key="slide.id"
				>
					<img
						v-if="slide.imagine && slide.imagine.length > 0"
						:src="
								`${databaseLink}${slide.imagine[0].url}`
						"
						:alt="slide.alternativeText"
					/>
					<div class="video-wrapper is-relative" v-else>
						<iframe
							class="youtube-video"
							:src="slide.url"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</swiper-slide>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>
		</div>
		<swiper
			ref="swiperThumbs"
			class="swiper gallery-thumbs"
			:options="swiperOptionThumbs"
		>
			<swiper-slide v-for="slide in slides" :key="slide.id">
				<img
					v-if="slide.imagine && slide.imagine.length > 0"
					:src="
						`${databaseLink}/${slide.imagine[0].formats.thumbnail.url}`
					"
					:alt="slide.alternativeText"
				/>
				<iframe
					v-else
					:src="slide.url"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
	export default {
		props: ['slides', 'width'],
		data() {
			return {
				swiperOption: {
					effect: 'coverflow',
					grabCursor: true,
					loop: true,
					loopedSlides: 1,
					slidesPerView: 'auto',
					centeredSlides: true,
					coverflowEffect: {
						rotate: 25,
						stretch: 0,
						depth: 100,
						modifier: 1.5,
						slideShadows: true,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
				swiperOptionThumbs: {
					loop: true,
					loopedSlides: 1,
					slidesPerView: 3,
					centeredSlides: true,
					touchRatio: 0.2,
					spaceBetween: 10,
					slideToClickedSlide: true,
				},
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
		},
		created() {
			// console.log('CAROUSEL', this.slides, this.width);
		},
		mounted() {
			this.$nextTick(() => {
				const swiperTop = this.$refs.swiperTop.$swiper;
				const swiperThumbs = this.$refs.swiperThumbs.$swiper;
				swiperTop.controller.control = swiperThumbs;
				swiperThumbs.controller.control = swiperTop;
			});
		},
	};
</script>

<style scoped lang="scss">
	.example-3d {
		width: 100%;
		height: 400px;
		padding: 2rem 0;
	}
	.swiper {
		height: 100%;
		width: 100%;

		.swiper-slide {
			background-color: $white;
			box-sizing: border-box;
			padding: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.video-wrapper {
			padding-top: 56.25%;
			position: relative;
			height: 0px;
			width: 100%;
			iframe {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
		}
		img {
			max-height: 100%;
		}
	}
	.gallery-top {
		.swiper-slide-next,
		.swiper-slide-prev {
			opacity: 0.4;
		}
	}
	.gallery-thumbs {
		box-sizing: border-box;
		padding: 0.5rem 0;
		height: 150px;
		.swiper-slide {
			border-radius: $round-corners;
			border: 2px solid lighten($gray, 25%);
			box-sizing: border-box;
			cursor: pointer;
			padding: 10px;
			transition: border-color 0.3s ease;
			&:hover {
				border-color: $secondary;
			}
			&:before {
				content: '';
				background: transparent;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}
		.swiper-slide-active {
			border-color: $secondary;
		}
		iframe {
			position: initial;
			height: 100%;
			width: 100%;
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		color: $primary;
	}
</style>
